import type { FontFamily } from '~/constants/templates/fonts'

export const OpenSansRegular: FontFamily = {
  name: 'Open Sans',
  weight: 400,
  format: 'truetype',
  url: '/fonts/OpenSans-Regular.ttf',
}

export const OpenSansSemiBold: FontFamily = {
  name: 'Open Sans',
  weight: 600,
  format: 'truetype',
  url: '/fonts/OpenSans-SemiBold.ttf',
}

export const OpenSansBold: FontFamily = {
  name: 'Open Sans',
  weight: 700,
  format: 'truetype',
  url: '/fonts/OpenSans-Bold.ttf',
}
