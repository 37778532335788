/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CardStatus = {
    status: CardStatus.status;
    reason?: CardStatus.reason;
};
export namespace CardStatus {
    export enum status {
        INACTIVE = 'INACTIVE',
        ACTIVE = 'ACTIVE',
        LOCKED = 'LOCKED',
        CANCELLED = 'CANCELLED',
        SUSPENDED = 'SUSPENDED',
    }
    export enum reason {
        STOLEN = 'STOLEN',
        LOST = 'LOST',
        CLOSED = 'CLOSED',
        NEVER_ACTIVATED = 'NEVER_ACTIVATED',
        INACTIVE = 'INACTIVE',
    }
}

