/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MarqetaCardAcceptorModel } from './MarqetaCardAcceptorModel';
import type { MarqetaWebhook } from './MarqetaWebhook';
export type TransactionSimulationRequest = {
    transaction_type: TransactionSimulationRequest.transaction_type;
    card_id?: string;
    merchant_id?: string;
    amount?: string;
    card_acceptor?: MarqetaCardAcceptorModel;
    webhook?: MarqetaWebhook;
    original_transaction_id?: string;
};
export namespace TransactionSimulationRequest {
    export enum transaction_type {
        AUTHORIZATION = 'AUTHORIZATION',
        AUTHORIZATION_ADVICE = 'AUTHORIZATION_ADVICE',
        CLEARING = 'CLEARING',
        REFUND = 'REFUND',
        REVERSAL = 'REVERSAL',
    }
}

