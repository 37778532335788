import type { Template } from './template'
import { Affinipay, Comcred, ITS, MerchantM, MerchantT } from './templates'

export const PRIME_RATE = 8.0

export enum BRAND_ID {
  Affinipay = '1b396b7d-16f8-496f-8890-5f95afc2e800',
  Affinipay2 = 'fdf2e5a4-c59b-4a1c-8113-cadf9ff7a21e',
  Comcred = 'b0187038-5fe0-44e5-a2e3-87c71f1f25fe',
  ITS = '49cf8ce9-2760-4b02-91e5-573cd193c906',
  MerchantM = 'a4f23d4c-f0b5-4dfb-bd02-63e758ebcc5f',
  MerchantT = '5415d428-dba0-4bc4-a8d8-0237e31affd3',
}

export const BRANDS: Record<string, Template> = {
  [BRAND_ID.Affinipay]: Affinipay,
  [BRAND_ID.Affinipay2]: Affinipay,
  [BRAND_ID.Comcred]: Comcred,
  [BRAND_ID.ITS]: ITS,
  [BRAND_ID.MerchantM]: MerchantM,
  [BRAND_ID.MerchantT]: MerchantT,
}
