/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BankAccountRequest = {
    bank_name: string;
    bank_account_number: string;
    bank_routing_number: string;
    bank_account_type: BankAccountRequest.bank_account_type;
    proof_of_bank_account?: Blob;
};
export namespace BankAccountRequest {
    export enum bank_account_type {
        CHECKING = 'CHECKING',
        SAVINGS = 'SAVINGS',
        ENUM_UNKNOWN = 'ENUM_UNKNOWN',
    }
}

