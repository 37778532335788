import { ThemeProvider } from '@mui/material/styles'
import type { FunctionComponent, ReactNode } from 'react'
import { useContext, createContext } from 'react'
import type { Template } from '~/constants/template'
import { PROGRAM_STATUS } from '~/constants/template'
import { getTheme } from '~/theme'
import { BRANDS } from '../../constants/brands'
import { PreloadFontHead } from '~/components/preload-font-head'

interface Props {
  children: ReactNode
  brandId: keyof typeof BRANDS
}

interface TemplateContext {
  template: Template
}

const baseTemplate: Template = {
  copy: {
    brandName: '',
    cardName: '',
    bankPartner: '',
    subdomain: '',
    email: '',
    links: {
      faq: '',
      homepage: '',
      legal: {
        loyaltyProgram: '',
        autopayAgreement: '',
        cardholderAgreement: '',
        ecommDisclosure: '',
        autodialerAuth: '',
        creditReportAuth: '',
        welcomeBonus: '',
      },
    },
    images: {
      logo: {
        src: '',
        width: 0,
        height: 0,
      },
      cardFrontSrc: '',
    },
    application: {
      title: [''],
      incentives: [{ title: '', copy: '' }],
      marginLow: 0,
      marginHigh: 0,
      feesRows: [{ title: '', explanation: '' }],
    },
    rewards: [],

    phoneNumber: '',
    offers: [],
    benefits: [],
  },
  usePrequal: false,
  gradient: '',
  cardOrientation: 'horizontal',
  theme: {},
  programStatus: PROGRAM_STATUS.Active,
  hiddenPaths: [],
}

const TemplateContext = createContext<TemplateContext>({
  template: baseTemplate,
})

export const TemplateProvider: FunctionComponent<Props> = ({
  children,
  brandId,
}) => {
  const isPageBranded = brandId !== ''

  const template: Template | undefined = isPageBranded
    ? BRANDS[brandId]
    : baseTemplate

  if (template === undefined)
    throw new Error(`Unable to load template for brandId: ${brandId}`)

  const theme = getTheme(template)

  return (
    <>
      <PreloadFontHead template={template} />

      <TemplateContext.Provider value={{ template }}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </TemplateContext.Provider>
    </>
  )
}

export const useTemplate = (): TemplateContext => {
  return useContext(TemplateContext)
}
