import type {
  ApplicationPageCopy,
  FeeRow,
  Incentive,
  Legal,
  Links,
  Offer,
  Reward,
} from '~/constants/template'
import type { FontFamily } from '~/constants/templates/fonts'
import { FONTS } from '~/constants/templates/fonts'

export const createTypographyStyle = (
  fontWeight: number,
  fontSize: number,
  lineHeight: string,
  letterSpacing = 0
) => {
  return {
    fontWeight,
    fontSize,
    lineHeight: lineHeight,
    letterSpacing,
  }
}

export const getFontFaceCSS = (fontFamilies: FontFamily[]) =>
  fontFamilies
    .map(
      font => `
  @font-face {
      font-family: '${font.name}';
      font-style: normal;
      font-display: block;
      font-weight: ${font.weight};
      src: local('${font.name}'), url(${font.url}) format('${font.format}');
    }
`
    )
    .join('\n')

export const SOFIA_PRO_FONT_FACE = getFontFaceCSS(FONTS.SofiaPro)

export const createDefaultIncentivesCopy = (
  brandName: string
): Array<Incentive> => {
  return [
    {
      title: '3x',
      copy: `On all ${brandName} purchases`,
    },
    {
      title: '2x',
      copy: `On all ${brandName} trips`,
    },
    {
      title: '1x',
      copy: `Anywhere else you use your card`,
    },
  ]
}

export const createDefaultFeeRows = (): Array<FeeRow> => {
  return [
    {
      title: 'Annual Fee',
      explanation: '$0',
    },
    {
      title: 'Foreign Transaction Fee',
      explanation: '$0',
    },
    {
      title: 'Late Payment Fee',
      explanation: '$40',
    },
    {
      title: 'Returned Payment Fee',
      explanation: '$30',
    },
  ]
}

export const createDefaultApplicationCopy = (
  brandName: string
): ApplicationPageCopy => {
  return {
    title: ['Get free trips with a premium credit card'],
    incentives: createDefaultIncentivesCopy(brandName),
    marginLow: 9.99,
    marginHigh: 22.99,
    feesRows: createDefaultFeeRows(),
  }
}

export const createDefaultOffers = (brandName: string): Array<Offer> => {
  return [
    {
      title: '3% back at herman miller',
      description: `Get 3% back when you use your ${brandName} Card at Herman Miller.`,
      image: `/brand/images/merchant-x/offer-herman-miller.webp`,
      linkHref: '',
      linkText: 'Shop Now',
    },
    {
      title: '3% back at herman miller',
      description: `Get 3% back when you use your ${brandName} Card at Herman Miller.`,
      image: `/brand/images/merchant-x/offer-herman-miller.webp`,
      linkHref: '',
      linkText: 'Shop Now',
    },
    {
      title: '2% back at herman miller',
      description: `Get 2% back when you use your ${brandName} Card at Herman Miller.`,
      image: `/brand/images/merchant-x/offer-herman-miller.webp`,
      linkHref: '',
      linkText: 'Shop Now',
    },
  ]
}

export const createDefaultRewards = (): Array<Reward> => {
  return [
    { title: '3x Points', copy: 'Copy for title 1' },
    { title: '2x Points', copy: 'Copy for title 2' },
    { title: '1x Point', copy: 'Copy for title 3' },
  ]
}

export const createDefaultLinks = (): Links => {
  return {
    faq: 'https://www.marqeta.com/',
    homepage: 'https://www.marqeta.com/',
    legal: {
      loyaltyProgram: '',
      autopayAgreement: '',
      cardholderAgreement: '',
      ecommDisclosure: '',
      autodialerAuth: '',
      creditReportAuth: '',
      welcomeBonus: '',
    },
  }
}

export const createBrandLegalLinks = (brandId: string): Legal => {
  return {
    loyaltyProgram: '',
    autopayAgreement: `${process.env.NEXT_PUBLIC_STATIC_BASE_URL}${brandId}/autopay_terms.pdf`,
    cardholderAgreement: '',
    ecommDisclosure: `${process.env.NEXT_PUBLIC_STATIC_BASE_URL}${brandId}/electronic_consent_agreement.pdf`,
    autodialerAuth: '',
    creditReportAuth: `${process.env.NEXT_PUBLIC_STATIC_BASE_URL}${brandId}/credit_report_authorization.pdf`,
    welcomeBonus: `${process.env.NEXT_PUBLIC_STATIC_BASE_URL}${brandId}/welcome_bonus.pdf`,
  }
}
