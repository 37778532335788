/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BankAccountResponse = {
    bank_account_id: string;
    bank_account_number_last_four: string;
    bank_name?: string;
    bank_status?: BankAccountResponse.bank_status;
    bank_account_type: BankAccountResponse.bank_account_type;
};
export namespace BankAccountResponse {
    export enum bank_status {
        ACTIVE = 'ACTIVE',
        PENDING = 'PENDING',
        INACTIVE = 'INACTIVE',
        ENUM_UNKNOWN = 'ENUM_UNKNOWN',
    }
    export enum bank_account_type {
        CHECKING = 'CHECKING',
        SAVINGS = 'SAVINGS',
        ENUM_UNKNOWN = 'ENUM_UNKNOWN',
    }
}

