/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VelocityControl } from './VelocityControl';
export type SecondaryCardRequest = {
    /**
     * Primary user ID for which the secondary card is being created. This property is required if the secondary user creates the card themselves OR if it is missing from the token claim. The field is omitted otherwise.
     */
    primary_user_id?: string;
    /**
     * Secondary user ID for which the secondary card is being created. This property is required if the primary user creates the card for the secondary user. The field is omitted otherwise.
     */
    secondary_user_id?: string;
    card_type?: SecondaryCardRequest.card_type;
    velocity_control?: VelocityControl;
};
export namespace SecondaryCardRequest {
    export enum card_type {
        VIRTUAL = 'VIRTUAL',
        PHYSICAL = 'PHYSICAL',
    }
}

