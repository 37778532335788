import { MulishBold, MulishRegular } from '~/constants/templates/fonts/mulish'
import {
  OpenSansBold,
  OpenSansRegular,
  OpenSansSemiBold,
} from '~/constants/templates/fonts/open-sans'
import {
  SofiaProBold,
  SofiaProRegular,
} from '~/constants/templates/fonts/sofia-pro'

export interface FontFamily {
  name: string
  weight: number
  format: string
  url: string
}

/**
 * Font configurations indexed by font family.
 */
export const FONTS: Record<string, FontFamily[]> = {
  SofiaPro: [SofiaProRegular, SofiaProBold],
  ['Open Sans, sans-serif']: [OpenSansRegular, OpenSansSemiBold, OpenSansBold],
  Mulish: [MulishRegular, MulishBold],
}
