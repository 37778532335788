/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BankAccountResponse } from './BankAccountResponse';
export type PaymentScheduleResponse = {
    payment_schedule_id: string;
    payment_date: string;
    payment_amount?: number;
    payment_schedule_status: PaymentScheduleResponse.payment_schedule_status;
    payment_type: PaymentScheduleResponse.payment_type;
    payment_frequency: PaymentScheduleResponse.payment_frequency;
    bank_account: BankAccountResponse;
};
export namespace PaymentScheduleResponse {
    export enum payment_schedule_status {
        ACTIVE = 'ACTIVE',
        COMPLETED = 'COMPLETED',
        CANCELLED = 'CANCELLED',
        ENUM_UNKNOWN = 'ENUM_UNKNOWN',
    }
    export enum payment_type {
        MINIMUM_PAYMENT = 'MINIMUM_PAYMENT',
        STATEMENT_BALANCE = 'STATEMENT_BALANCE',
        CURRENT_BALANCE = 'CURRENT_BALANCE',
        FIXED_AMOUNT = 'FIXED_AMOUNT',
        ENUM_UNKNOWN = 'ENUM_UNKNOWN',
    }
    export enum payment_frequency {
        MONTHLY_SPECIFIC_DAY = 'MONTHLY_SPECIFIC_DAY',
        ONE_TIME = 'ONE_TIME',
        ENUM_UNKNOWN = 'ENUM_UNKNOWN',
    }
}

