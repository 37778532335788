import Head from 'next/head'
import type { FunctionComponent } from 'react'
import type { Template } from '~/constants/template'
import { FONTS } from '~/constants/templates/fonts'

interface Props {
  template: Template
}

/**
 * Preload font files for the specified template to ensure they're available as soon as possible on initial page load.
 *
 * @param {Template} template - The template configuration.
 */
export const PreloadFontHead: FunctionComponent<Props> = ({ template }) => {
  const typography = template.theme.typography
  const isFontFamilyConfigured =
    typeof typography !== 'function' && typography?.fontFamily !== undefined
  const fontFamily: string = isFontFamilyConfigured
    ? typography.fontFamily?.toString() || ''
    : ''

  return (
    <Head>
      {isFontFamilyConfigured &&
        FONTS[fontFamily].map((font, index) => {
          const mimeType = `font/${font.url.split('.').pop()}`

          return (
            <link
              key={index}
              rel="preload"
              href={font.url}
              as="font"
              type={mimeType}
              crossOrigin="anonymous"
              fetchPriority="high"
            />
          )
        })}
    </Head>
  )
}
